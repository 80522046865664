@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");

:root {
  --main-hg-color: #5e64ff;
}

#root {
  height: 100vh;
  width: 100%;
}

*[disabled_link="1"],
*[disabled_link="1"]:hover,
*[disabled_link="1"]:active,
*[disabled_link="1"]:visited {
  text-decoration: none;
  color: inherit;
}

.main {
  z-index: 100;
}

.relafull {
  height: 100%;
  width: 100%;
  position: relative;
}

*[color="1"] {
  color: rgba(0, 0, 0, 0.6);
}

*[color="2"] {
  color: #39a2db;
}

*[color="3"] {
  color: white;
}

*[color="4"] {
  color: rgba(0, 0, 0, 0.2);
}

*[bg_color="1"],
*[h_bg_color="1"]:hover {
  background: rgba(0, 0, 0, 0.6);
}

*[bg_color="2"],
*[h_bg_color="2"]:hover {
  background: var(--main-hg-color);
}

*[bg_color="3"],
*[h_bg_color="3"]:hover {
  background: white;
}

*[bg_color="4"],
*[h_bg_color="4"]:hover,
.left_menu_per[active_color="1"],
.left_menu_per:hover {
  background: rgba(73, 136, 252, 0.05);
  color: var(--main-hg-color);
  /* font-weight: 500; */
}

*[bg_color="5"],
*[h_bg_color="5"]:hover {
  background: var(--main-hg-color);
  color: white;
  font-weight: 400;
}

/* *[bg_color="6"],
*[h_bg_color="6"]:hover {
  background: rgb(238, 122, 142);
  color: white;
  font-weight: 400;
} */

*[border_color="1"],
*[h_border_color="1"]:hover {
  border-color: rgba(0, 0, 0, 0.6);
}

/* *[border_color="2"],
*[h_border_color="2"]:hover,
.left_menu_per[active_color="1"],
.left_menu_per:hover{
    border-color: #6155A6;
} */
.left_menu_per_name {
  font-size: 15px;
  margin-left: 5px;
}

.left_menu_per[active_color="1"] .left_menu_per_name::after {
  content: "";
  position: absolute;
  height: 100%;
  background: var(--main-hg-color);
  width: 10px;
  right: 0;
  top: 0;
  border-radius: 10px 0 0 10px;
  animation: left_per_init 0.4s;
}

@keyframes left_per_init {
  from {
    opacity: 0.7;
    transform: scale(0.4);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

*[border_color="3"],
*[h_border_color="3"]:hover {
  border-color: white;
}

*[border_color="4"],
*[h_border_color="4"]:hover {
  border-color: rgba(0, 0, 0, 0.1);
}

.absfull {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

*[trans="500"] {
  -webkit-transition: 500ms;
  -moz-transition: 500ms;
  -o-transition: 500ms;
  transition: 500ms;
}

*[trans="300"] {
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
}

.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.button_flex {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.button_flex svg {
  margin-left: 10px;
  font-size: 18px;
}

.login_container {
  padding-top: 100px;
}

.login {
  width: 400px;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.login_image {
  height: 80px;
}

.login_title {
  padding-top: 20px;
  font-family: "Maven Pro", "Helvetica Neue", "HelveticaNeue", Helvetica, Arial,
    sans-serif;
  font-size: 20px;
  text-align: center;
  padding-bottom: 20px;
}

.login_field {
  height: 36px;
  background: transparent;
  border: 1px solid #ccc;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-size: 13px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.login_field:focus {
  border-color: #2c6ee1;
  box-shadow: 0 0 3px #4186ec;
  outline: 0;
}

.login_button,
.fileadd_button,
.main_button,
.main_button_new {
  margin-top: 20px;
  text-align: center;
  background-color: #ffc820;
  border-color: #ffc820;
  font-family: "Maven Pro", "Helvetica Neue", "HelveticaNeue", Helvetica, Arial,
    sans-serif;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.66px;
  line-height: 20px;
  text-decoration: none;
  text-transform: uppercase;
  padding: 6px 18px;
  cursor: pointer;
}

.login_link {
  text-align: center;
  color: #3867b6;
  cursor: pointer;
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
}

.login_footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f4f4f4;
  color: #666;
  border-top: none;
  height: 45px;
  text-align: center;
  line-height: 45px;
  font-size: 10px;
}

.loading {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 3px;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100;
  opacity: 1;
  visibility: visible;
}

.loading[loading="0"] {
  opacity: 0;
  visibility: hidden;
}

.home_container {
  display: flex;
  flex-direction: row;
}

.main_content {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}

.left_menu {
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.left_menu_container {
}

.header {
  height: 60px;
  padding: 10px 0;
  border-bottom: 1px solid #ededed;
}

.header_logo {
  height: 100%;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
}

.header_logo img {
  height: 100%;
}

.header_logo_name {
  font-weight: bold;
  letter-spacing: 3px;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.main_header {
  position: relative;
  display: flex;
  flex-direction: row;
}

.user_info {
  height: 100%;
  padding-right: 20px;
  width: 80px;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.header_search {
  position: relative;
  flex: 1;
  height: 100%;
}

.user_info_container {
  width: 40px;
  height: 40px;
  background: var(--main-hg-color);
  color: white;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  overflow: hidden;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 22px;
  cursor: pointer;
}
.user_info_container img,
.user_dropdown_image img {
  height: 100%;
}

.header_tooltip_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

.header_tooltip {
  height: 100%;
  width: 40px;
  font-size: 24px;
}

.user_dropdown {
  position: absolute;
  top: 48px;
  right: 10px;
  min-height: 40px;
  min-width: 200px;
  padding: 20px 20px 10px 20px;
  background: white;
  border: 1px solid #ededed;
  box-shadow: 0px 8px 16px 0px rgba(25, 24, 35, 0.15);
  -webkit-box-shadow: 0px 8px 16px 0px rgba(25, 24, 35, 0.15);
  -moz-box-shadow: 0px 8px 16px 0px rgba(25, 24, 35, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
}

.user_dropdown[is_show="1"] {
  opacity: 1;
  visibility: visible;
}

.user_dropdown_image {
  width: 80px;
  height: 80px;
  overflow: hidden;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.2);
  font-size: 22px;
  font-weight: bold;
}

.user_dropdown_name {
  padding-top: 10px;
  font-weight: bold;
  letter-spacing: 1px;
}

.user_dropdown_email {
  padding-bottom: 10px;
}

.user_dropdown_mobile {
  font-size: 12px;
}

.user_dropdown_logout {
  width: 100%;
  height: 40px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  cursor: pointer;
}

.left_menu_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-right: 1px solid #ededed;
  box-shadow: 0px 8px 16px 0px rgba(25, 24, 35, 0.15);
  -webkit-box-shadow: 0px 8px 16px 0px rgba(25, 24, 35, 0.15);
  -moz-box-shadow: 0px 8px 16px 0px rgba(25, 24, 35, 0.15);
}

.left_menu_per {
  flex-direction: row;
  display: flex;
  align-items: center;
  position: relative;
  color: rgb(48, 48, 48);
  text-decoration: none;
  padding: 5px 60px 5px 40px;
  background: white;
  border-right: 3px solid transparent;
  transition: ease-out 0.3s;
}

.left_menu_per + .left_menu_per {
  margin-top: 10px;
}

.left_menu_per:hover,
.left_menu_per:active {
  /* color: inherit; */
  text-decoration: none;
}

.left_menu_per_icon {
  width: 30px;
  font-size: 16px;
}

.left_menu_button {
  padding: 10px 20px;
}

.btn {
  padding: 10px 0;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  /* font-weight: bold; */
  cursor: pointer;
}

button:focus {
  /* box-shadow: 0 0 0 4px rgba(50, 128, 230, 0.582); */
  transition: ease-out 0.3s;
}

.main_content_container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.main_dock {
  height: 50px;
  border-bottom: 1px solid #ededed;
  font-size: 20px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  text-transform: capitalize;
}

.drag_footer_box {
  background: rgba(56, 103, 182, 0.7);
  color: white;
  border: 1px solid #3867b6;
  position: absolute;
  width: 255px;
  height: 18px;
  left: 10px;
  bottom: 15px;
  font-size: 12px;
  text-align: center;
}

.upload_container,
.preview_container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1000;
  background: white;
  opacity: 0;
  display: flex;
  flex-direction: column;
  visibility: hidden;
  -webkit-transition: 500ms;
  -moz-transition: 500ms;
  -o-transition: 500ms;
  transition: 500ms;
}

.upload_container[is_show="1"],
.preview_container[is_show="1"] {
  opacity: 1;
  visibility: visible;
  display: flex;
  flex-direction: column;
}

.upload_header,
.preview_header {
  display: flex;
  height: 60px;
  border-bottom: 1px solid #ededed;
  flex-direction: row;
  padding: 10px 0;
}

.upload_header_close {
  width: 60px;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
}

.upload_header_ind_container {
  flex: 1;
  display: flex;
  flex-direction: row;
  color: rgba(255, 255, 255, 0.6);
}

.upload_header_ind {
  padding-right: 20px;
  line-height: 20px;
}

.upload_header_ind .anticon {
  font-size: 16px;
}

.upload_header_ind:before {
  font-size: 20px;
  font-weight: bold;
  content: "- - -";
  padding-right: 20px;
}

.upload_header_ind:first-child:before {
  display: none;
}

.upload_header_ind[active="1"] {
  color: white;
}

.upload_header_ind .anticon {
  display: none;
}

.upload_header_ind[active="1"] .anticon[active="1"] {
  display: inline-block;
}

.upload_header_ind[active="0"] .anticon[active="0"] {
  display: inline-block;
}

.upload_header_back {
  padding-left: 20px;
}

.upload_header[active_step="0"] .upload_header_back {
  display: none;
}

.upload_header_next {
  padding-right: 10px;
}

.upload_header_back .btn,
.upload_header_next .btn {
  height: 100%;
  padding: 0 20px;
}

.upload_body {
  flex: 1;
  height: 100%;
}

.upload_footer {
  height: 30px;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: row;
  border-top: 1px solid #ededed;
  padding: 0 20px;
}

.upload_footer_menu_container {
  flex: 1;
}

.upload_footer_powered {
  display: flex;
  align-items: center;
}

.upload_footer_copy {
  display: flex;
  align-items: center;
}

.step0_container {
}

.upload_section {
  position: relative;
  background: rgba(0, 0, 0, 0.04);
  width: 400px;
  height: 400px;
  -webkit-border-radius: 500px;
  -moz-border-radius: 500px;
  border-radius: 500px;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.second_circle {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0);
  -webkit-border-radius: 500px;
  -moz-border-radius: 500px;
  border-radius: 500px;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
}

.upload_section:hover .second_circle {
  background: rgba(0, 0, 0, 0.04);
  left: -50px;
  right: -50px;
  top: -50px;
  bottom: -50px;
}

.upload_section img {
  width: 80px;
}

.upload_section_title {
  padding-top: 10px;
  font-size: 16px;
}

.upload_section_body {
  font-size: 12px;
}

.step0_container .ant-upload-list {
  display: none;
}

.upload_dragger {
  opacity: 0;
  position: absolute !important;
}

.step1_container {
  display: flex;
  flex-direction: row;
}

.step1_detail {
  width: 300px;
  border-right: 1px solid #ededed;
  padding: 20px;

  overflow-y: auto;
  max-height: calc(100vh - 60px);
}

/* .parts_scroll_area{
  margin-top: 20px;
  max-height: calc(100vh - 295px);
  overflow-y: auto;
  overflow-x: visible;
} */
.step1_user_detail,
.step1_file_detail {
  border: 1px solid #ededed;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  /* overflow: hidden; */
  box-shadow: 0px 8px 16px 0px rgba(25, 24, 35, 0.15);
  -webkit-box-shadow: 0px 8px 16px 0px rgba(25, 24, 35, 0.15);
  -moz-box-shadow: 0px 8px 16px 0px rgba(25, 24, 35, 0.15);
}

.preview_waiting {
  margin: 0 0 0 auto;
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  opacity: 0.8;
}

.step1_file_detail {
  margin-top: 20px;
}

.step1_user_detail_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ededed;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
}

.step1_user_detail_title .cancelled {
  font-size: 12px;
  color: rgb(223, 22, 22);
}

.step1_user_signed {
  position: absolute;
  right: 20px;
  top: 10px;
}

.step1_user_detail[issigned="0"] svg {
  display: none;
}

.step1_user_detail[issigned="0"] {
  opacity: 0.7 !important;
  box-shadow: none;
}

.step1_user_detail_body {
  padding: 0 20px;
}

.step1_body,
.step3_body {
  flex: 1;
  position: relative;
}

.step1_body > .absfull {
  padding-left: 30px;
  overflow: auto;
}

.step_title {
  font-size: 34px;
  line-height: 34px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.parts_container {
  width: 500px;
  display: flex;
  flex-direction: column;
}

.per_part {
  padding: 20px;
  position: relative;
  width: 100%;
  border: 1px solid #ededed;
  border-left: 5px solid #ededed;
  background: white;
  box-shadow: 0px 8px 16px 0px rgba(25, 24, 35, 0.15);
  -webkit-box-shadow: 0px 8px 16px 0px rgba(25, 24, 35, 0.15);
  -moz-box-shadow: 0px 8px 16px 0px rgba(25, 24, 35, 0.15);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.per_part[role="2"] *[role="email"] {
  display: none;
}

.remove_button {
  position: absolute;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.4);
  right: -60px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  display: none;
}

.remove_button > div {
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.per_part[removable="1"] .remove_button {
  display: flex;
}

.input_title {
  font-weight: bold;
  padding-top: 10px;
}

.input_body {
  padding-top: 4px;
}

.input_suffix {
  position: absolute;
  border-left: 1px solid #ededed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 30px;
  cursor: pointer;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.4);
}

.selectcontact .ant-modal-body {
  padding: 0;
}

.role_container {
  position: absolute;
  right: 10px;
  top: 0;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.draggable_container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  padding-left: 30px;
}

.draggable_fields_container {
  width: 240px;
  display: flex;
  flex-direction: column;
  background: white;
  position: relative;
  z-index: 1;
  border-right: 1px solid #ededed;
}

.draggable_pdf_container {
  flex: 1;
  display: flex;
  overflow: auto;
  background: white;
  background: rgba(0, 0, 0, 0.2);
  justify-content: center;
  position: relative;
}

.drag_field_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 36px;
  justify-content: center;
  cursor: pointer;
  padding-left: 10px;
  position: relative;
}

.drag_field_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
}

.drag_field_icon_box {
  width: 24px;
  height: 24px;
  background: #ffd65b;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.remove_drag_drop {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  height: 60px;
  background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  background: rgba(255, 110, 110, 0.5);
  border: 1px solid rgba(255, 110, 110, 1);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  opacity: 0;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
}

.remove_drag_drop {
  background: rgba(255, 110, 110, 0.7);
}

.drag_field_name {
  display: flex;
  align-items: center;
  flex: 1;
  text-transform: capitalize;
  justify-content: space-between;
  width: "100%";
  padding-right: 10px;
}
.drag_field_name .characters {
  font-size: 11px;
  opacity: 0.5;
}

.seperator_field {
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 190px;
  background: rgba(0, 0, 0, 0.2);
  height: 1px;
  display: none;
}

.drag_field_container:hover,
.drag_field_container[active="1"] {
  background: rgba(0, 0, 0, 0.2);
}

.drag_field_title {
  padding: 20px 20px 0px 20px;
  font-weight: bold;
}

.draggable_fill {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 214, 91, 0.75);
  display: flex;
  flex-direction: column;
  opacity: 0;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.react-draggable-dragged {
  opacity: 1;
}

.contact_container {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.review_pdf_container {
  max-width: 100%;
  position: relative;
  max-height: 100%;
}
.pdf_controller {
  display: flex;
  align-items: center;
  padding: 0 20px;
  flex-grow: 1;
  justify-content: flex-end;
}
.pdf_controller > .ant-btn {
  margin: 5px;
}

.react-pdf__Page__canvas {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.review_pdf_container_forward {
  height: 100%;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.review_pdf_container_back,
.review_pdf_container_action {
  height: 100%;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.review_pdf_container_total {
  height: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.review_pdf_tools {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.review_pdf_fixed_close {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 20px;
  cursor: pointer;
}

.review_pdf_fixed {
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  z-index: 102;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
  display: flex;
  flex-direction: column;
}

.review_root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.review_pdf_fixed .react-pdf__Page__annotations {
  display: none;
}

.review_pdf_fixed[visible="1"] {
  opacity: 1;
  visibility: visible;
}

.review_pdf_fixed_header {
  height: 60px;
  border-bottom: 1px solid #ededed;
  width: 100%;
  display: flex;
  flex-direction: row;
  background: white;
}

.role_container {
  position: absolute;
  right: 0;
  top: 0;
}

.checked_role {
  cursor: pointer;
}

.checked_role_per,
.checked_role_per_active {
  padding-right: 5px;
}

.checked_role_per_active {
  padding: 10px 15px;
  width: 200px;
  text-align: right;
}

.document_watermark_container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.document_watermark_container_dropable {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
}

.dropped {
  width: 100px;
  height: 100px;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
}

.dragele {
  background: black;
  width: 50px;
  height: 50px;
}

.upload_steps_body {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 20px;
}

.clear_div {
  position: fixed;
  top: -10000px;
  right: -1000px;
}

.drop_box_sign {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}

.drop_box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 1;
  z-index: 5;
  font-size: 10px;
  font-family: "Roboto";
  text-align: center;
}
.drop_box_name {
  white-space: pre-wrap;
}

.upload_steps_add_rec {
  width: 600px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.upload_steps_add_rec_title {
  font-size: 34px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.upload_steps_add_rec_per_input {
  width: 100%;
  border: 1px solid #868686;
  height: 30px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding-left: 10px;
}

.upload_steps_add_rec_per_title {
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 5px;
}

.home_router_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  position: relative;
}

.manage_router_container {
  display: flex;
  flex: 1;
  position: relative;
}

.manage_router_container > .absfull {
  padding: 20px;
}

.filter_container {
  transition: ease-in-out 0.3s;
  cursor: pointer;
  position: relative;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  box-shadow: 0px 0px 4px 0px rgba(25, 24, 35, 0.15);
  -webkit-box-shadow: 0px 0px 4px 0px rgba(25, 24, 35, 0.15);
  -moz-box-shadow: 0px 0px 4px 0px rgba(25, 24, 35, 0.15);
  border: 1px solid #ededed;
}

.home_filter_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-row-gap: 15px;
}

.filter_title {
  font-size: 16px;
  border-bottom: 1px solid #ededed;
  padding: 10px 20px;
}

.filter_body {
  display: flex;
  font-size: 30px;
  padding: 5px 20px;
  flex-direction: row;
  min-width: 120px;
}

.filter_icon {
  width: 40px;
  font-size: 30px;
}

.filter_data {
  flex: 1;
  justify-content: flex-end;
  display: flex;
}

.filter_container[boxsize="small"] .filter_title {
  font-size: 14px;
  padding: 5px 10px;
}

.filter_container[boxsize="small"] .filter_body {
  font-size: 20px;
  min-width: 100px;
  padding: 5px 10px;
}

.filter_container[boxsize="small"] .filter_icon {
  font-size: 20px;
  text-align: left;
}

.home_filter_table {
  margin-top: 20px;
  background: white;
}

.filter_container[active="1"] {
  /* background: rgba(57, 162, 219, 0.3); */
  box-shadow: 0 0 2px 4px rgba(94, 100, 255, 0.7);
}

.status_container {
  text-transform: capitalize;
}

.mini_title {
  font-size: 20px;
  padding-bottom: 20px;
}

.settings_page_wrapper {
  padding: 30px 20px;
  padding-bottom: 0;
}

.settings_page_wrapper .settings_box + .settings_box {
  margin-top: 20px;
}

.settings_box {
  border: 1px solid #ededed;
  padding: 20px 0 0 20px;
  border-radius: 10px;
  width: min(calc(100% - 20px), 600px);
  position: relative;
}

.settings_box[loading]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1px);
  border-radius: 10px;
  z-index: 3;
}

.settings_box[loading]::after {
  z-index: 4;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  content: "";
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border-top: 2px solid #2c6ee1;
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #2c6ee1;
  animation: loaderAnimation 1s infinite linear;
}

@keyframes loaderAnimation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.settings_box .settings_box_title {
  font-size: 16px;
  padding: 20px 0 40px 0;
}

.settings_box .settings_box_item {
  border-top: 1px solid #ededed;
  padding: 18px 0;
  display: grid;
  grid-template-columns: 140px auto;
  align-items: center;
}

.settings_box_item .settings_item_label {
  font-size: 12px;
  color: #868686;
}

.settings_box_item input[type="email"],
.settings_box_item input[type="password"] {
  border: 1px solid #b9b9b9;
  padding: 4px 10px;
  border-radius: 4px;
  width: 220px;
  transition: ease-out 0.3s;
  margin: 0 30px 0 auto;
}

.settings_box_item .settings_right_align {
  grid-column: 2;
  margin: 20px 30px 0 auto;
}

.settings_box_item .settings_flex_bttns {
  display: flex;
  justify-content: flex-end;
}

.settings_box_item button {
  width: max-content;
  /* background: #277af5;
  color: white; */
  border: none;
  padding: 4px 14px;
  border-radius: 2px;
  transition: ease-out 0.3s;
  cursor: pointer;
}

.settings_box_item button:disabled {
  opacity: 0.6;
}

/* .settings_box_item button:hover {
  background: #277af5;
  box-shadow: 0 0 4px rgb(0, 0, 0, 0.6);
} */
.settings_box_item input[disabled] {
  color: #b1b1b1;
}

.settings_box_item input::placeholder {
  font-size: 13.5px;
  color: #d1d1d1;
}

.settings_box_item input[error] {
  border: 1px solid red;
}

.settings_box_item input[error]:focus {
  box-shadow: 0 0 0px 3px rgba(241, 28, 28, 0.582);
}

.settings_box_item input:focus {
  outline: none;
  box-shadow: 0 0 0px 3px rgba(50, 128, 230, 0.582);
}

.settings_box_error {
  background: rgba(252, 17, 56, 0.795);
  color: white;
  padding: 10px;
  margin: 0 30px 20px 0;
  border-radius: 4px;
}

.doc_info_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  margin: 0 20px;
  color: white;
}

.doc_info_title b {
  margin-left: 15px;
}

.doc_info_date {
  font-size: 11px;
  line-height: 10px;
  opacity: 0.7;
  text-align: right;
}

.step1_actions {
  display: flex;
  flex-direction: column;
}

.step1_actions button {
  border: none;
  transition: ease-out 0.3s;
  text-transform: uppercase;
}

.step1_actions button + button {
  margin-top: 10px;
}

/* .step1_actions button:focus{
  box-shadow: 0 0 0 4px rgba(50, 128, 230, 0.582);
} */
.action_button {
  border: none;
  padding: 6px 30px;
  transition: ease-in-out 0.3s;
}

.action_button[disabled] {
  opacity: 0.7;
}

.drag {
  position: absolute;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.drag_box_overlay {
  position: absolute;
  top: 0%;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 4;
}

.download_options {
  display: flex;
  position: relative;
}

.download_options .download_options_loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.4);
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(1px);
}

.download_options .download_options_loader svg {
  color: var(--main-hg-color);
  font-size: 24px;
}

.download_options[isloading="0"] .download_options_loader {
  display: none;
}

.download_options button {
  z-index: 3;
}

.download_options .download_option {
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: relative;
  flex: 1;
}

.download_options .download_option span {
  margin: 30px 0;
  text-align: center;
  z-index: 3;
}

.download_options .download_option_bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.2;
  display: flex;
}

.download_options .download_option_bg span {
  font-size: 70px;
  margin: 10px auto auto;
  color: var(--main-hg-color);
}

/* .download_options .download_option + .download_option {
  border-left: 1px solid #eee;
} */

.boxes_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
}

.boxes_wrapper .boxes_drag_area {
  height: 100%;
  position: relative;
  width: 100%;
}

#app_overlay {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1000;
  background: #2c6ee1;
  display: none;
}

.drag_disabled {
  opacity: 0.7;
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 5;
  color: var(--main-hg-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader svg {
  font-size: 35px;
}

.stamp_username {
  font-size: 12px;
  opacity: 0.8;
}

.left_menu_hamburger {
  display: none;
  position: absolute;
  padding: 0 20px;
  top: 0;
  left: 0;
  z-index: 4;
}

.mobile_header_overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: white;
  z-index: 9;
  transition: ease-out 0.3s;
}

.mobile_header_overlay .mobile_close_x {
  font-size: 30px;
  align-items: center;
  display: flex;
  width: max-content;
  padding: 10px 20px;
  margin: 0 0 0 auto;
}

.mobile_header_overlay .mobile_overlay_item {
  padding: 0 20px;
}

.left_menu_hamburger .mobile_logo {
  height: 40px;
}

.review_info_trigger {
  font-size: 20px;
  display: none;
  justify-content: center;
  align-items: center;
  padding: 0 10px 0 20px;
  color: var(--main-hg-color);
  z-index: 3;
}

.preview_info_overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: max-content;
  transition: ease-out 0.3s;
  display: grid;
  grid-template-columns: auto 1fr;
  z-index: 101;
}

.preview_info_overlay .preview_info_contents {
  background: white;
  animation: slide 0.3s;
  padding: 20px 40px 20px 20px;
  position: relative;
}

.preview_close_x {
  position: absolute;
  top: 5px;
  right: 10px;
}

.opacity_overlay {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  animation: overlay_init 1s;
}

@keyframes slide {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes overlay_init {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media (min-width: 770px) {
  .contacts .contacts_content {
    min-width: 670px;
  }
  .template_preview .sidebar {
    padding-right: 15px;
  }
}

@media (max-width: 769px) {
  .template_preview {
    flex-direction: column;
  }
  .drawer_title {
    flex-direction: column;
  }
  .file_container {
    grid-template-rows: auto 1fr;
    grid-template-columns: none !important;
  }
  .contacts {
    flex-direction: column;
    padding: 5px;
  }

  .contact_content {
    width: 100%;
  }

  .left_menu {
    display: none;
  }

  .left_menu_hamburger {
    display: block;
  }

  .upload_header_ind_label {
    display: none;
  }

  .upload_header_ind {
    padding-right: 5px;
  }

  .upload_header_ind:before {
    content: "-";
    padding-right: 5px;
  }

  .step1_detail {
    display: none;
  }

  .review_info_trigger {
    display: flex;
  }
}

.preview_reminder {
  background: #eee;
  padding: 10px 10px;
  border-radius: 5px;
  color: var(--main-hg-color);
  display: flex;
  align-items: center;
}

.preview_reminder svg {
  font-size: 20px;
  margin-right: 10px;
}

.page_loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 3rem;
}

.last_selected {
  border-radius: 4px;
  margin: auto 10px 10px 10px;
  padding: 8px;
}

.last_selected .last_selected_title {
  border-bottom: 1px solid #eee;
  margin-bottom: 4px;
  padding: 4px 0;
}

.last_selected .last_selected_container {
  padding: 0 4px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
}

.last_selected .last_selected_container span {
  margin: 4px 5px;
}

.last_selected .last_selected_delete {
  background: rgba(0, 0, 0, 0.4);
  height: 25px;
  width: 25px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
}

.step1_detail_status {
  border: 1px solid #eee;
  margin-bottom: 10px;
  padding: 2px 5px;
}

.trees_container {
  width: 120px;
  background: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin-right: 20px;
}

.trees_container span {
  color: rgb(0, 0, 0);
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
}

.trees_container div {
  color: rgb(109, 109, 109);
  font-size: 11px;
}

.trees_container .card_icon {
  font-size: 32px;
  margin-bottom: 10px;
}

.contacts {
  display: flex;
  min-height: 200px;
}

.contacts .contacts_sidebar {
  border-right: 1px solid rgb(199, 199, 199);
  padding: 10px 15px;
}

.contacts .contacts_sidebar .sidebar_item + .sidebar_item {
  margin-top: 10px;
}

.contacts .contacts_sidebar .non_active:hover {
  background: rgb(218, 218, 218);
  border-right: 3px solid rgb(218, 218, 218);
}

.contacts .contacts_sidebar .sidebar_item {
  transition: ease-out 0.3s;
  background: rgb(248, 248, 248);
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  border-right: 3px solid rgb(248, 248, 248);
  cursor: pointer;
}

.contacts .contacts_sidebar .active {
  border-right: 3px solid var(--main-hg-color);
}

.contacts .contacts_sidebar .sidebar_item svg {
  margin-right: 7px;
  fill: var(--main-hg-color);
}

.add_contacts {
  position: absolute;
  padding: 20px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.contact_delete {
  text-align: center;
  color: rgb(216, 53, 53);
}

.contacts_content {
  min-height: 300px;
}

.templates {
  padding: 10px;
}
.templates .templates_map {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

.template {
  border-radius: 4px;
  min-width: 200px;
  width: max-content;
  overflow: hidden;
  background: var(--main-hg-color);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  font-family: "Rubik", sans-serif;
}
.template .template_create {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 30px;
  cursor: pointer;
  transition: ease-in-out 0.5s;
}
.template .template_create:hover {
  background: rgba(0, 0, 0, 0.4);
}
.template .template_banner {
  padding: 40px 15px 10px 15px;
  color: white;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  /* background: linear-gradient(to left, #D3CCE3, #E9E4F0); */
}
.template_banner .temp_ellipsis {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 20px;
  font-weight: 700;
  z-index: 6;
  cursor: pointer;
}
.template_drop {
  padding: 5px 0;
}

.template_drop div {
  transition: ease-out 0.3s;
  font-size: 13px;
  cursor: pointer;
  padding: 2px 5px;
}
.template_drop div svg {
  margin-right: 5px;
}

.template_drop div:hover {
  background: #eee;
}
.template .template_banner span {
  position: relative;
  z-index: 2;
}
.template .template_banner::before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  border-radius: 4px;
  border: 3px solid #3d42c9;
  top: -3px;
  left: 40px;
  transform: rotate(45deg);
  z-index: 1;
}
.template .template_banner::after {
  position: absolute;
  content: "";
  height: 60px;
  width: 60px;
  border-radius: 4px;
  background: #3d42c9;
  bottom: -3px;
  right: 10px;
  transform: rotate(45deg);
  z-index: 1;
}

.template .template_info {
  background: rgb(255, 255, 255);
  padding: 10px 15px;
  border-radius: 2px;
}

.template_info_double {
  display: flex;
  /* grid-template-columns: 1fr 1fr; */
  justify-content: space-around;
  color: rgb(77, 77, 77);
  font-weight: lighter;
}
.template_info_double span:nth-child(2) {
  margin-left: 10px;
  color: var(--main-hg-color);
}
.template .template_info_footer {
  border-top: 1px solid #eee;
  padding: 5px 0;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;
  margin-top: 10px;
}

.template .template_info_footer button {
  border: none;
  transition: ease-out 0.3s;
  cursor: pointer;
}

.template .template_info_footer button:nth-child(1) {
  border-radius: 50%;
  padding: 5px 10px;
}

.template .template_info_footer button:nth-child(1):hover {
  background: rgb(243, 44, 54);
  color: white;
}
.template .template_info_footer button:nth-child(2) {
  border-radius: 15px;
  padding: 5px 10px;
}

.template .template_info_footer button:nth-child(2):hover {
  background: var(--main-hg-color);
  color: white;
}
.drawer_title {
  margin-right: 35px;
  display: flex;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}
.custom_drawer .ant-drawer-header {
  background: var(--main-hg-color);
}
.custom_drawer .ant-drawer-header .ant-steps-item-title {
  color: white !important;
}
.file_container {
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
}
/* .file_container .file_container_sidebar{

} */

.file_container .file_container_header {
  display: flex;
  flex-direction: column;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-wrap: wrap;
  padding: 5px 10px;
}

.file_container .file_info_box {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  padding: 15px;
  border-radius: 4px;
  width: max-content;
  width: 100%;
}

.file_container .file_info_box .file_info_header {
  font-size: 15px;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.file_info_box .double_info {
  display: flex;
  flex-direction: column;
  font-size: 13px;
}

.file_info_box .double_info text:nth-child(1) {
  font-weight: 500;
}

.file_info_box .double_info text:nth-child(2) {
  opacity: 0.7;
  margin-left: 5px;
}
.template_participants {
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  overflow-y: auto;
}

.user_comp {
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 14px 18px;
  position: relative;
  max-width: 340px;
}

.user_comp .del_x {
  position: absolute;
  height: 20px;
  width: 20px;
  right: -30px;
  background: rgba(0, 0, 0, 0.2);
  top: calc(50% - 10px);
  border-radius: 50%;
  color: white;
  cursor: pointer;
}

.user_comp .left_highlight {
  width: 8px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 4px 0 0 4px;
}

.template_preview {
  display: flex;
  height: 100%;
}
.template_preview .sidebar {
  display: flex;
  flex-direction: column;
  grid-row-gap: 15px;
}

.contact_template {
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(48, 48, 48, 0.5);
  overflow: hidden;
}

.contact_template .ct_highlight {
  width: 100%;
  padding: 8px 8px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  align-items: center;
}
.contact_template .ct_highlight div {
  opacity: 0.6;
}

.contact_template .ct_role {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 10px;
}

.myEditor {
  border: 1px solid #eee;
}
.myEditor .editors {
  padding: 5px;
}
.myEditor .editors svg {
  margin: 0 3px;
}

#pdf-cont {
}

#konva-stage {
  width: auto !important;
  height: auto !important;
}

.textarea {
  display: block;
  width: 100%;
  overflow: hidden;
  resize: both;
  min-height: 40px;
  line-height: 20px;
}

.textarea[contenteditable]:empty::before {
  content: "Placeholder still possible";
  color: gray;
}

textarea::placeholder {
  color: black !important;
  resize: both;
  white-space: pre-wrap !important;
  font-family: "Roboto", sans-serif !important;
}

.login_button {
  width: 100%;
}
.yourSignature {
  width: 230px;
  height: 64px;
  cursor: move;
  margin-bottom: 8px;
  padding: 10px;
  color: rgb(47, 47, 47);
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}

.settings-tabs-margin .ant-tabs-nav-wrap {
  margin-left: 20px;
}

.upload-image-sign {
  border-radius: 10px;
  width: min(calc(100% - 20px), 600px);
  position: relative;
}

.upload-image-button {
  margin: -30px 0px 15px 0px;
  width: min(calc(100% - 20px), 600px);
  position: relative;
}
.settings-form-btn .ant-col {
  align-items: end;
}
.recover-ant-text {
  font-weight: 300;
}

.recover-ant-text .ant-alert-message {
  margin-left: 30px;
  margin-top: -2px;
  font-weight: 200;
  font-size: 18px;
}

.image-data-list {
  padding: 5px;
  max-width: 1310px;
  max-height: 700px;
  overflow-x: auto;
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr 1fr;
}

.image-uploaded-list {
  /* width: 250px; */
  height: 150px;
  margin: 5px;
  border: 1px solid rgb(240, 240, 240);
  border-radius: 8px;
}

.upload-css {
  position: relative;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #5e64ff;
  border: 2px solid #5e64ff;
  color: #fff;
  text-align: center;
  font-size: 0.875em;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.02em;
  border-radius: 4px;
  height: 2.5em;
  flex-shrink: 0;
  white-space: nowrap;
  padding: 0 0.9em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  min-width: 11em;
}

.upload-css:hover {
  background-color: #fff;
  color: #5e64ff;
}

.survey {
  border: 3px solid red;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 14px;
  padding: 14px;
  width: 200px;
  font-size: 12px;
  margin: 4px;
  text-align: center;
}
.survey a {
  background-color: red;
  color: white;
  padding: 6px 8px;
  border-radius: 4px;
}
.survey p {
  text-align: start;
}
